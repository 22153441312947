import React from 'react';
import "./assets/css/style.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet';

export default function Karir(){
    const handleClickScroll = () => {
        let element = document.getElementById("aboutme");
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      };
    return(
        <>
        <Helmet>
            <title>Jose Upmind Digital Optima | Karir</title>
        </Helmet>
        <div className='container-fluid background-hero'>
            <div className='container pt-5'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='wrapper-logo-jose'>
                        <LazyLoadImage 
                            className='img img-fluid'
                            src='https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/joseupmind/new/Logo-JOSE-UPMIND.png'   alt='logo joseupmind'
                            effect="blur"
                        />
                        </div>
                    </div>
                </div>
                <div className='row justify-content-center align-items-center display-switch-order'>
                    <div className='col-lg-6 col-md-6 col-xs-12 col-12'>
                        <div className='wrapper-image-section-1 d-flex justify-content-center'>
                            <LazyLoadImage 
                                className='img img-fluid' 
                                src='https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/joseupmind/new/Image-Karir.png' 
                                alt='img section 1'
                                effect="blur"
                                />
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-xs-12 col-12'>
                        <div className='wrapper-text-side-career py-5'>
                            <h2 className='text-center text-white font-custom-50'>
                                Cari Tempat <del>Kerja</del> Belajar Upgrade dan Bermain Yang Lebih Seru?
                            </h2>
                            <p className='text-center text-white w-75'>
                                Kami lagi cari orang-orang H.E.P.I. yang bisa bantu wujudkan misi perusahaan: Kamu bukan?
                            </p>
                            <button 
                                className='btn btn-primary btn-lg btn-block btn-custom w-75'
                                onClick={handleClickScroll}
                                >
                                BERGABUNG BERSAMA KAMI
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='container margin-custom-top-100' id='aboutme'>
            <div className='row'>
                <div className='col-lg-12 col-md-12'>
                    <h1 className='text-center'>
                        Perusahaan Ini Mau Ngapain?
                    </h1>
                </div>
            </div>
            <div className='row my-5'>
                <div className='col-lg-12 col-md-12 col-12'>
                    <div className="card shadow p-3 mb-5 bg-white rounded no-border-card">
                        <div className="card-body">
                            <div className='wrappe-image-section-career d-flex justify-content-center'>
                                <LazyLoadImage 
                                    className='img img-fluid'
                                    src='https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/joseupmind/new/Pexels-Image.png'   alt='logo joseupmind'
                                    effect="blur"
                                />
                            </div>
                            <div className='mx-auto w-custome-80 my-4'>
                            <p className='text-left'>
                                Kami percaya bahwa satu wanita bahagia, bisa membuat dunia jadi lebih indah. Ketika wanita bahagia, maka sekelilingnya akan tertular kebahagiaan. Dan sebaliknya, ketika wanita sedang menderita, maka lingkungan sekitarnya pun ikut menderita. Itulah kenapa kami percaya, bahwa wanita adalah katalisator energi paling berpengaruh di dunia.
                            </p>
                            <p className='text-left'>
                                Sayangnya, kesuksesan wanita saat ini, TIDAK MENJAMIN kebahagiaan mereka dalam romansa dan kehidupan. Dan hal tersebut BERPENGARUH BESAR terhadap kualitas produktivitas, komunikasi, daya asuh, dan kesehatan mental yang dimiliki wanita.
                            </p>
                            <p className='text-left'>
                                Bantu kami untuk menyebarkan mindset, strategi, dan skill yang membuat wanita terus berdaya dan mampu bahagia. Yuk join!
                                Lihat peluang kerja yang tersedia, siapa tau jodoh.
                            </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='container-fluid'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12 col-md-12'>
                        <h1 className='text-center'>
                            Company Overview
                        </h1>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-6'>
                    <div className='background-address-company'>
                        <div className='wrappe-icon-set'>
                            <LazyLoadImage
                             className='img img-fluid' 
                             src='https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/joseupmind/new/Headquarters-Icon.png' 
                             alt='img section 1'
                             effect="blur"
                            />
                            <h2 className='text-center my-3'>
                                <b>Headquarters</b><br/>
                                Bogor, Kota Hujan <br/> Indonesia
                            </h2>
                        </div>
                    </div>
                </div>
                <div className='col-lg-6'>
                    <div className='d-flex justify-content-center align-items-center w-100 h-100'>
                        <div className='row my-5'>
                            <div className='col-md-6'>
                            <div className='wrapper-items-company'>
                                <LazyLoadImage
                                    className='img img-fluid' 
                                    src='https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/joseupmind/new/noun-presentation.png' 
                                    alt='img section 1'
                                    effect="blur"
                                />
                                <h3>
                                    Services
                                </h3>
                                <p className='w-75'>
                                    Platform upgrade diri, public dan inhouse training, private coaching, penerbitan buku
                                </p>
                            </div>
                            </div>
                            <div className='col-md-6'>
                            <div className='wrapper-items-company'>
                                <LazyLoadImage
                                    className='img img-fluid' 
                                    src='https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/joseupmind/new/noun-friends.png' 
                                    alt='img section 1'
                                    effect="blur"
                                />
                                <h3>
                                    Company Size
                                </h3>
                                <p className='w-75'>
                                    Perusahaan swasta dengan kapasitas 15 -30 orang
                                    tim H.E.P.I. | Kami adalah tim berjiwa muda dengan range usia 25 - 35 tahun.
                                </p>
                            </div>
                            </div>
                            <div className='col-md-6'>
                            <div className='wrapper-items-company'>
                                <LazyLoadImage
                                    className='img img-fluid' 
                                    src='https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/joseupmind/new/noun-target.png' 
                                    alt='img section 1'
                                    effect="blur"
                                />
                                <h3>
                                    Our Mission
                                </h3>
                                <p className='w-75'>
                                    Perusahaan swasta dengan kapasitas 15 -30 orang
                                    tim H.E.P.I. | Kami adalah tim berjiwa muda dengan range usia 25 - 35 tahun.
                                </p>
                            </div>
                            </div>
                            <div className='col-md-6'>
                            <div className='wrapper-items-company'>
                                <LazyLoadImage
                                    className='img img-fluid' 
                                    src='https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/joseupmind/new/noun-calendar.png' 
                                    alt='img section 1'
                                    effect="blur"
                                />
                                <h3>
                                    History
                                </h3>
                                <p className='w-75'>
                                2016    | Tercetus untuk dirikan perusahaan<br/>
                                2017 - 2018	  | Pemimpin market romansa wanita<br/>
                                2019 - 2020	| Beradaptasi dengan nuklir pandemi<br/>
                                2021 - 2022	 | Tahun belajar, keluar dari krisis<br/>
                                2023 - now	  | Pulih dengan jiwa baru
                                </p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='container-fluid background-fixed-section4'>
            <div className='container'>
                <div className='row pt-5'>
                    <div className='col-lg-12 col-md-12 col-12'>
                        <h1 className='pt-5 text-center text-white'>
                            Nilai dan Prinsip Perusahaan Kita
                        </h1>
                        <p className='text-center text-white '>
                            Sebelum kamu lihat-lihat apa saja lowongan kerja yang tersedia, lebih baik baca dulu <br/> prinsip yang kita pegang. Kalo gak cocok, jangan dipaksain cocok ya…
                        </p>
                    </div>
                </div>
                <div className='row mb-5 custom-width-icon-items'>
                    <div className='col-lg-12'>
                        <ul className='list-items-custom'>
                            <li className='py-3'>
                                <div className='wrapper-items-icon'>
                                    <LazyLoadImage
                                        src='https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/joseupmind/Point.png'
                                        alt='icon dot'
                                    />
                                    <div className='content-item'>
                                        <h4 className='text-white'>
                                            Heartful Service
                                        </h4>
                                        <ul className='text-white'>
                                            <li className='text-white'>
                                                <p className='mb-0'>
                                                   - Dahulukan kebahagiaan dan kepuasan customer dengan jujur tanpa tapi
                                                </p>
                                            </li>
                                            <li>
                                                <p className='mb-0'>
                                                    - Selalu dengarkan customer sebelum merumuskan solusi
                                                </p>
                                            </li>
                                            <li>
                                                <p className='mb-0'>
                                                    - Tingkatkan kualitas produk dan service setiap saat, hingga jadi yang terbaik di market
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li className='py-3'>
                                <div className='wrapper-items-icon'>
                                    <LazyLoadImage
                                        src='https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/joseupmind/Point.png'
                                        alt='icon dot'
                                    />
                                    <div className='content-item'>
                                        <h4 className='text-white'>
                                            Esteem
                                        </h4>
                                        <ul className='text-white'>
                                            <li className='text-white'>
                                                <p className='mb-0'>
                                                    - Tunjukan rasa hormat dengan sapaan awal mas / mba / teteh / akang
                                                </p>
                                            </li>
                                            <li>
                                                <p className='mb-0'>
                                                    - Biasakan ucapkan TERIMA KASIH, MAAF, dan TOLONG pada siapapun
                                                </p>
                                            </li>
                                            <li>
                                                <p className='mb-0'>
                                                    - Selalu berkata jujur dengan perkataan yang baik apapun yang terjadi
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li className='py-3'>
                                <div className='wrapper-items-icon'>
                                    <LazyLoadImage
                                        src='https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/joseupmind/Point.png'
                                        alt='icon dot'
                                    />
                                    <div className='content-item'>
                                        <h4 className='text-white'>
                                            Personal Growth
                                        </h4>
                                        <ul className='text-white'>
                                            <li className='text-white'>
                                                <p className='mb-0'>
                                                    - Say Yes! Tapi bukan jadi Yes Man!
                                                </p>
                                            </li>
                                            <li>
                                                <p className='mb-0'>
                                                    - Jadi baik aja gak cukup, perlu kompeten dan bertumbuh
                                                </p>
                                            </li>
                                            <li>
                                                <p className='mb-0'>
                                                    - Proaktif temukan cara lebih efektif dan efisien untuk mencapai hasil
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li className='py-3'>
                                <div className='wrapper-items-icon'>
                                    <LazyLoadImage
                                        src='https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/joseupmind/Point.png'
                                        alt='icon dot'
                                    />
                                    <div className='content-item'>
                                        <h4 className='text-white'>
                                            Integrate
                                        </h4>
                                        <ul className='text-white'>
                                            <li className='text-white'>
                                                <p className='mb-0'>
                                                    - Bersatu untuk selesaikan pekerjaan sekarang!
                                                </p>
                                            </li>
                                            <li>
                                                <p className='mb-0'>
                                                    - Hanya bicara ide dan solusi atas tantangan yang dihadapi
                                                </p>
                                            </li>
                                            <li>
                                                <p className='mb-0'>
                                                    - Memberi dan menerima feedback dengan ekologis untuk hasil kerja yang lebih baik
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className='col-lg-12'>
                        <p className='text-white'>
                            Kami sedang cari tim yang punya ciri-ciri seperti diatas, jika memang lowongannya <br/> tersedia segera ajukan lamaran ya!
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div className='container margin-custom-top-100'>
            <div className='row mb-5'>
                <div className='col-lg-12 col-12'>
                    <h1 className='text-center'>
                        Kenapa Perlu Gabung Sama Kita?
                    </h1>
                </div>
            </div>
            <div className='row justify-content-center align-items-center'>
                <div className='row my-5 justify-content-center align-items-center'>
                    <div className='col-lg-5 col-sm-5 col-md-5 col-12 text-center'>
                       <LazyLoadImage
                            className='img img-fluid' 
                            src='https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/joseupmind/kenapa-perlu-gabung-1.png' 
                            alt='img section 1'
                            effect="blur"
                       />
                    </div>
                    <div className='col-lg-5 col-sm-5  col-md-5 col-12'>
                        <div className='wrapper-desc-join'>
                            <h4 className='pb-3'>
                                KARENA INI BUKAN CUMA KERJA
                            </h4>
                            <p>
                                Kami gak percaya dengan budaya kerja-kerja-kerja-tipes! Kami percaya bahwa kebahagiaan customer juga sangat ditentukan oleh kebahagiaan tim dalam berbagai aspek kehidupannya.
                                Yuk bareng-bareng jadi tim H.E.P.I untuk bikin customer happy!
                            </p>
                        </div>
                    </div>
                </div>
                <div className='row my-5 justify-content-center align-items-center'>
                    <div className='col-lg-5 col-sm-5 col-md-5 col-12 order-lg-2 order-md-2 order-sm-2 order-xs-1 text-center'>
                       <LazyLoadImage
                            className='img img-fluid' 
                            src='https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/joseupmind/kenapa-perlu-gabung-3.png' 
                            alt='img section 1'
                            effect="blur"
                       />
                    </div>
                    <div className='col-lg-5 col-sm-5  col-md-5 col-12'>
                        <div className='wrapper-desc-join'>
                            <h4 className='pb-3'>
                                KARENA KAMI ADALAH PIONIR
                                DAN PEMIMPIN PASAR
                            </h4>
                            <p>
                                Kami adalah pelopor platform dan training romansa untuk wanita di Indonesia. Founder perusahaan ini (Jose Aditya) adalah seorang pelatih romansa wanita #1 Indonesia. Memiliki 743k subscriber di youtube, 258k instagram followers, dan 150k email subscriber selama 2 tahun terakhir. Numbers don’t lie!
                            </p>
                        </div>
                    </div>
                </div>
                <div className='row my-5 justify-content-center align-items-center'>
                    <div className='col-lg-5 col-sm-5 col-md-5 col-12 text-center'>
                       <LazyLoadImage
                            className='img img-fluid' 
                            src='https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/joseupmind/kenapa-perlu-gabung-2.png' 
                            alt='img section 1'
                            effect="blur"
                       />
                    </div>
                    <div className='col-lg-5 col-sm-5  col-md-5 col-12'>
                        <div className='wrapper-desc-join'>
                            <h4 className='pb-3'>
                                KARENA INDUSTRI INI MASIH SANGAT HIJAU
                            </h4>
                            <p>
                                Dilansir dari halaman Forbes, industri ini sudah menghasilkan
                                $107 Milyar USD! Dan diproyeksikan akan bertumbuh menjadi $325 Milyar USD pada tahun 2025. Masih banyak banget
                                peluang yang bisa kita raih bersama!
                            </p>
                        </div>
                    </div>
                </div>
                <div className='row my-5 justify-content-center align-items-center'>
                    <div className='col-lg-5 col-sm-5 col-md-5 col-12 order-lg-2 order-md-2 order-sm-2 text-center'>
                       <LazyLoadImage
                            className='img img-fluid' 
                            src='https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/joseupmind/kenapa-perlu-gabung-4.png' 
                            alt='img section 1'
                            effect="blur"
                       />
                    </div>
                    <div className='col-lg-5 col-sm-5  col-md-5 col-12'>
                        <div className='wrapper-desc-join'>
                            <h4 className='pb-3'>
                                KARENA WANITA PERLU BAHAGIA
                            </h4>
                            <p>
                                Wanita yang menderita, adalah awal dari kekacauan dan ketidakseimbangan dunia. Wanita bahagia bisa jadi tim yang sangat produktif bersama pasangannya untuk membangun
                                dunia jadi lebih indah.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='container-fluid background-grey-section-last'>
            <div className='container margin-custom-top-100 py-5'>
                <div className='row py-5'>
                    <div className='col-lg-12 col-md-12'>
                        <h1 className='text-center'>
                             Lowongan Yang Tersedia
                        </h1>
                    </div>
                </div>
                <div className='row py-3 justify-content-center'>
                    <div className='col-lg-5'>
                        <div className="card w-100 no-border-card img-width-custom">
                            <LazyLoadImage 
                                src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/joseupmind/career/Front-End-Developer.png" 
                                className="card-img-top" 
                                alt="paltform"
                                effect="blur"
                            />
                        </div>
                    </div>
                    <div className='col-lg-5'>
                        <div className="card w-100 no-border-card img-width-custom">
                            <LazyLoadImage 
                                src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/joseupmind/career/Team-Optimizer.png" 
                                className="card-img-top" 
                                alt="paltform"
                                effect="blur"
                            />
                        </div>
                    </div>
                </div>
                <div className='row py-3 pb-5 justify-content-center'>
                    <div className='col-lg-5'>
                        <div className="card w-100 no-border-card img-width-custom">
                            <LazyLoadImage 
                                src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/joseupmind/career/Copy-Writer.png" 
                                className="card-img-top" 
                                alt="paltform"
                                effect="blur"
                            />
                        </div>
                    </div>
                    <div className='col-lg-5'>
                        <div className="card w-100 no-border-card img-width-custom">
                            <LazyLoadImage 
                                src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/joseupmind/career/Video-Editor.png" 
                                className="card-img-top" 
                                alt="paltform"
                                effect="blur"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='container-fluid background-footer'>
            <div className='container'>
                <div className='row mt-5 justify-content-between align-items-center'>
                <div className='col-lg-6 col-md-6 col-12'>
                    <div className='row justify-content-center align-items-center'>
                    <div className='col-sm-4'>
                        <LazyLoadImage 
                        className='img img-fluid' 
                        src='https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/joseupmind/logo-upmind-jose.png'  
                        alt='logo joseupmind'
                        effect="blur"
                        />
                    </div>
                    <div className='col-sm-8'>
                        <div className='wrapper-text-address'>
                        <p className='text-white'>
                        PT. Jose Upmind Digital Optima
                        Permata Regency D/37, Jl H. Kelik RT 1 / RW 6, Srengseng,
                        Kembangan, Jakarta Barat 11630
                        </p>
                        </div>
                    </div>
                    </div>
                </div>
                <div className='col-lg-6 col-md-6 col-12'>
                    <div className='row justify-content-center align-items-center'>
                    <div className='col-sm-4'>
                        <h3 className='text-white'>
                        Contact Us
                        </h3>
                        <p className='text-white font-size-custom-12' 
                        onClick={() => {
                        window.location = 'mailto:management@joseupmind.com';
                        }} >
                        Email: <u>management@joseupmind.com</u>
                        </p>
                    </div>
                    <div className='col-sm-4'>
                        <h3 className='text-white'>
                        Join Us
                        </h3>
                        <p className='text-white font-size-custom-12'
                         onClick={()=>{
                            window.location.replace('https://joseupmind.com/karir');
                        }}
                        >
                            <u>Halaman Karir</u>
                        </p>
                    </div>
                    <div className='col-sm-4'>
                        <div className='wrapper-sosial-media'>
                        <LazyLoadImage 
                        src='https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/joseupmind/FB-icon.svg' 
                        alt='facebook'
                        effect="blur"
                        onClick={()=>{
                            window.location.replace('https://web.facebook.com/trulavofficial/');
                        }}
                        />
                        <LazyLoadImage 
                        src='https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/joseupmind/IG-icon.svg' 
                        alt='instagram'
                        effect="blur"
                        onClick={()=>{
                            window.location.replace('https://www.instagram.com/trulav.id/');
                        }}
                        />
                        <LazyLoadImage 
                        src='https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/joseupmind/YT-icon.svg' 
                        alt='youtube'
                        effect="blur"
                        onClick={()=>{
                            window.location.replace('https://www.youtube.com/@JoseAditya');
                        }}
                        />
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div className='row mt-5'>
                <div className='col-lg-12'>
                    <p className='text-center text-white font-footer'>
                    Copyright © 2023 PT Jose Upmind Digital Optima
                    </p>
                </div>
                </div>
            </div>
        </div>
        </>
    );
}